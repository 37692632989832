import React from 'react';

// components
import Landing from './landing/Landing';
import Projects from './projects/Projects';
import Nav from './Nav'
import Footer from '../Footer';
import ContactMe from './contact/ContactMe';

const Portfolio: React.FC = () => {
  return (
    <div className="container">
      <Nav />
      <Landing />
      <Projects />
      <ContactMe />
      <Footer />
    </div>
  );
};

export default Portfolio;