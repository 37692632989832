import React from 'react';

const Landing: React.FC = () => {
  return (
    <div className="landing">
      <div className="landing-inner">
        <h1>Ernesto Godoy</h1>
        <p>Front-end focused full-stack developer based in San Diego, CA.</p>
      </div>
      <div className="scroll-down"></div>
    </div>
  );
};

export default Landing;