import React from 'react';
import { FiLink, FiGithub } from 'react-icons/fi'
import projectData from '../../../data/projectData'

const Projects: React.FC = () => {
  return (
    <div className="projects">
      <h1>select projects</h1>
      <div className="line"></div>
      { projectData.map((p: any, i) => {
        return (
          <div key={ i } className={ i%2===0 ? "project-even" : "project-odd" }>
            <div className="project-inner">
              <h1>{p.name}</h1>
              <div className="img-wrapper">
                <img src={ p.img } alt="" />
              </div>
              <div className="description">
                <div className="stack">
                  { p.stack.map((s: any, j: number) => {
                    return ( 
                      <span key={ j }>{ s }</span>
                    )
                  })}
                </div>
                <div className="description-links">
                  <a href={ p.repo } target="_blank" rel="noopener noreferrer"><FiGithub /></a>
                  <a href={ p.demo } target="_blank" rel="noopener noreferrer"><FiLink /></a>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default Projects;