const projectData = [
  // {
  //   id: "001",
  //   name:"Lumii Ai",
  //   img: "./assets/images/placeholder.png",
  //   // description: 'This app was completed in a mob while practicing paired programming in which, along with a team mate, I was responsible for the frontend. Our ultimate goal for this app was to make planning an artinerary for a trip alot easier. This app was created using React in the frontend, Rails in the backend, and PostgreSQL was used for our database. Rails Devise feature was used for authentication and Bootstrap was also used for styling purposes.',
  //   stack: ["Angular", "TypeScript", "HTML", "SCSS", "Flexbox", "Highcharts"],
  //   repo: undefined,
  //   demo: "https://lumii.ai/" 
  // },
  {
    id: "002",
    name: "AppTrack",
    img: "./assets/images/apptrack.png",
    // description: 'This app was completed in a mob while practicing paired programming in which, along with a team mate, I was responsible for the frontend. Our ultimate goal for this app was to make planning an artinerary for a trip alot easier. This app was created using React in the frontend, Rails in the backend, and PostgreSQL was used for our database. Rails Devise feature was used for authentication and Bootstrap was also used for styling purposes.',
    stack: ["React", "React Router Dom", "React Hooks", "JavaScript", "HTML", "Bootstrap", "Ruby on Rails", "PostgreSQL", "Highcharts"],
    repo: "https://github.com/ernstgodoy/application_tracker",
    demo: "https://app-track-r.herokuapp.com/", 
  },
  {
    id: "003",
    name:"This Portfolio!",
    img: "./assets/images/portfolio.png",
    // description: 'This app was completed in a mob while practicing paired programming in which, along with a team mate, I was responsible for the frontend. Our ultimate goal for this app was to make planning an artinerary for a trip alot easier. This app was created using React in the frontend, Rails in the backend, and PostgreSQL was used for our database. Rails Devise feature was used for authentication and Bootstrap was also used for styling purposes.',
    stack: ["React", "React Router Dom", "TypeScript", "SCSS", "HTML", "Flexbox"],
    repo: "https://github.com/ernstgodoy/portfolio",
    demo:"https://ernestogodoy.com/" 
  },
  {
    id: "004",
    name: "Trip Planner",
    img: "./assets/images/tripplanner.png",
    // description: 'This app was completed in a mob while practicing paired programming in which, along with a team mate, I was responsible for the frontend. Our ultimate goal for this app was to make planning an artinerary for a trip alot easier. This app was created using React in the frontend, Rails in the backend, and PostgreSQL was used for our database. Rails Devise feature was used for authentication and Bootstrap was also used for styling purposes.',
    stack: ["React", "React Router Dom", "JavaScript", "HTML", "React Bootstrap", "Ruby on Rails", "PostgreSQL"],
    repo: "https://github.com/teamjade2020/TripPlanner",
    demo: "https://glacial-bastion-31936.herokuapp.com/" 
  },
  {
    id: "005",
    name: "Stellar High Five",
    img: "./assets/images/shf.png",
    // description: 'Stellar High Five is a full stack app that uses the twitter gem to send a recepient of your choice a high five via twitter for being, or doing something stellar. This was a revival project where, as a team, we had to upgrade an outdated version of rails and update the Front End from backboneJS with coffee script to ReactJS.',stack: ["React", "React Hooks", "JavaScript", "HTML", "Bootstrap", "Ruby on Rails", "PostgreSQL"],
    stack: ["React", "JavaScript", "HTML", "CSS", "Ruby on Rails"],
    repo: undefined,
    demo: "https://stellar-high-five-staging.herokuapp.com/"
  },
  {
    id: "006",
    name: "Movie Rater",
    img: "./assets/images/movierater.png",
    // description: 'This app was completed in a mob while practicing paired programming in which, along with a team mate, I was responsible for the frontend. Our ultimate goal for this app was to make planning an artinerary for a trip alot easier. This app was created using React in the frontend, Rails in the backend, and PostgreSQL was used for our database. Rails Devise feature was used for authentication and Bootstrap was also used for styling purposes.',
    stack: ["React", "React Router Dom", "React Hooks", "JavaScript", "HTML", "React Bootstrap", "Ruby on Rails", "PostgreSQL"],
    repo: "https://github.com/ernstgodoy/movies",
    demo:"https://ratemovie-s.herokuapp.com/" 
  },
]

export default projectData